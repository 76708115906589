.genreList {
    display: block;
}
.seeMore {
    cursor: pointer;
}
.btn-pill {
    border-radius: 15px;
}
.listOfGenres {
    display: block;
} 